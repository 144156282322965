*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
 
}

/* .slick-slide > div{
    margin: 0 10px;
}

.slick-prev, .slick-next {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
   
    z-index: 1;
  }
  
  .slick-prev:hover, .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .slick-prev, .slick-next {
    width: 100px;  
    height: 100px;  
    z-index: 1;
  } 
*/
 
 